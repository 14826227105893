import request from '@/utils/request.js'

export function pageInit(){
    return request({
        url:'/api/warehousePutInNote/pageInit',
        method:'get'
    })
}

export function listPage(params){
    return request({
        url:'/api/warehousePutInNote/listPage',
        method:'get',
        params
    })
}

export function addInit() {
    return request({
        url:'/api/warehousePutInNote/addInit',
        method:'get'
    })
}

export function getProductListPage(params) {
    return request({
      url: "/api/PurchasePlan/productListPage",
      params,
      method: "get" 
    })
} 
export function warehousePutInNote(data) {
    return request({
      url: "/api/warehousePutInNote",
      data,
      method: "post" 
    })
} 
export function batchUpdate(data) {
    return request({
      url: "/api/warehousePutInNote/batchUpdate",
      data,
      method: "post" 
    })
}
export function updateInit(id) {
    return request({
      url: `/api/warehousePutInNote/updateInit/${id}`,
      method: "get" 
    })
}
export function detail(id) {
    return request({
      url: `/api/warehousePutInNote/${id}`,
      method: "get" 
    })
}

export function getRelatedQualityCheckNoteNo(id) {
    return request({
        url:`/api/warehousePutInNote/getRelatedQualityCheckNoteNo/${id}`,
        method:'get'
    });
}
export function getRelatedReceiveNoteDo(id) {
    return request({
        url:`/api/warehousePutInNote/getRelatedReceiveNoteDo/${id}`,
        method:'get'
    });
}
export function update(data) {
    return request({
        url:`/api/warehousePutInNote`,
        method:'put',
        data
    });
}
export function updateTips(data) {
    return request({
        url:`/api/warehousePutInNote/updateTips`,
        method:'post',
        data
    });
}


//打印入库单
export function printInBound(data) {
    return request({
        url:`/api/warehousePutInNote/batchPrintInBound`,
        method:'post',
        data
    });
}

// 操作记录
export function operationLog(params) {
    return request({
        url:`/api/warehouseOperateRecord/list`,
        method:'get',
        params
    })
}



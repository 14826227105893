<template>
    <div class="inboundManual">
        <el-tabs v-model="pageInfo.status" type="card" @tab-click="find">
            <el-tab-pane label="全部" name="all"></el-tab-pane>
            <template v-for="(item,index) in statusTypeList">
                <el-tab-pane 
                :label="item.value" 
                :name="item.id"
                 v-if="index>0"
                :key="item.id">
                    <span slot="label">{{item.value}} <span v-if="item.size>0&&item.size">({{item.size}})</span></span>
                </el-tab-pane>
            </template>
        </el-tabs>
        <div style="margin-top:10px">
            <el-select clearable v-model="pageInfo.timeType" placeholder="入库时间" style="width:130px;margin-right:10px">
                <el-option
                  v-for="item in searchTimeTypeList"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id">
                </el-option>
            </el-select>
            <el-date-picker
            clearable
            style="margin-right:10px;position:relative;top:1px"
            v-model="pageInfo.time"
            type="daterange"
            range-separator="至"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
            </el-date-picker>
            <el-select clearable v-model="pageInfo.type" placeholder="全部类型" style="width:170px;margin-right:10px">
                <el-option
                    v-for="item in putInType"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id">
                </el-option>
            </el-select>
            <el-select clearable v-model="pageInfo.storageId" placeholder="全部仓库" style="width:170px;margin-right:10px">
                <el-option
                    v-for="item in storageList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id">
                </el-option>
            </el-select>
            <el-input clearable v-model="pageInfo.keyword" placeholder="搜索内容" style="width:300px;margin-right:10px">
                <el-select style="width:120px" slot="prepend" v-model="pageInfo.searchType" placeholder="入库单号">
                    <el-option
                        v-for="item in searchTypeList"
                        :key="item.id"
                        :label="item.value"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-input>
            <el-button type="primary" @click="find">查询</el-button> <br><br>
            <el-button type="primary" v-if="pageInfo.status=='all'" @click="addInit">添加入库单</el-button>

            <el-dropdown v-if="pageInfo.status=='all'" trigger="click" placement="bottom">
                <el-button  style="margin:-2px 10px 0">批量操作 <i class="el-icon-arrow-down"></i></el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="batchUpdate('toSubmit','请选择待提交/已驳回的入库单！')">批量提交</el-dropdown-item>
                    <el-dropdown-item @click.native="batchUpdate('submitAndPutIn','请选择待提交/已驳回的入库单！')">批量批量提交并入库</el-dropdown-item>
                    <el-dropdown-item @click.native="batchUpdate('delete','请选择待提交/待审批/待入库/已驳回的入库单！')">批量删除</el-dropdown-item>
                    <el-dropdown-item @click.native="batchUpdate('toWarehouse','请选择待入库的入库单！')">批量确认入库</el-dropdown-item>
                    <el-dropdown-item @click.native="batchUpload('入库单')">批量导入入库单</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>

            <template v-if="pageInfo.status=='toSubmit'">
                <el-button @click.native="batchUpdate('toSubmit','请选择待提交/已驳回的入库单！')">批量提交</el-button>
                <el-button @click.native="batchUpdate('submitAndPutIn','请选择待提交/已驳回的入库单！')">批量批量提交并入库</el-button>
                <el-button @click.native="batchUpdate('delete','请选择待提交/待审批/待入库/已驳回的入库单！')">批量删除</el-button>
            </template>

            <template v-if="pageInfo.status=='toWarehouse'">
                <el-button @click.native="batchUpdate('toWarehouse','请选择待入库的入库单！')">批量确认入库</el-button>
                <el-button @click.native="batchUpdate('delete','请选择待提交/待审批/待入库/已驳回的入库单！')">批量删除</el-button>
            </template>


            <el-button @click="batchPrintInBound">批量打印</el-button>


        </div>
        <div style="flex:1;overflow:auto;padding-top:10px">
            <el-table
                v-loading='loading'
                height="100%"
                class="outTable"
                :span-method="arraySpanMethod"
                @selection-change="select"
                :data="tableData"
                style="width: 100%;border-top:1px solid #EBEEF5"
                row-key="childId"
                default-expand-all
                :cell-style="{ fontSize: '13px', padding: '7px 0' }"
                :tree-props="{children: 'itemList'}"
                :header-cell-style="{
                    fontSize: '13px',
                    padding: '9px 0',
                    marginTop: '10px',
                }">
                <el-table-column
                    type="selection"
                    width="width">
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="图片"
                    width="width">
                    <template slot-scope="scope">
                        <div v-if="scope.row.itemList!=undefined" class="tableMenu">
                            <div>
                                <CopyButton :content='scope.row.ibNo'>
                                    <el-button slot="copy" type="text">{{scope.row.ibNo}}</el-button>
                                </CopyButton>
                            </div>
                            <div>
                                <span>入库仓库：</span>
                                <span>{{scope.row.storageName}}</span>
                            </div>
                            <div>
                                <span>类型：</span>
                                <span>{{scope.row.typeName}}</span>
                            </div>
                            <div>
                                <span>创建人/创建时间：</span>
                                <span>{{scope.row.createName}}/{{scope.row.createTime}}</span>
                            </div>
                            <div>
                                <span>备注：</span>
                                <span>{{scope.row.tips}}</span>
                                <el-popover
                                placement="bottom"
                                width="250"
                                ref="tip"
                                @hide='hiddenTip(scope.row.id)'
                                trigger="click">
                                    <div>
                                        <el-input type="textarea" rows="4" v-model="tipsContent" placeholder=""></el-input>
                                    </div>
                                    <span @click="tipsContent=scope.row.tips" class="el-icon-edit" slot="reference" style="color:#409EFF;margin-left:10px;cursor:pointer"></span>
                                </el-popover>
                            </div>
                            <div style="flex:1;text-align:right;padding-right:20px;">
                                <span v-if="scope.row.statusName=='已完成'" class="status">{{scope.row.statusName}}</span>
                                <span v-if="scope.row.statusName=='已撤销'" class="status red">{{scope.row.statusName}}</span>
                                <span v-if="scope.row.statusName=='待入库'" class="status orange">{{scope.row.statusName}}</span>
                                <span v-if="scope.row.statusName=='待提交'" class="status green">{{scope.row.statusName}}</span>
                                <el-button type="text" style="margin:-2px 15px 0" @click="getDetail(scope.row.id)">详情</el-button>
                                <el-dropdown trigger="click" placement='bottom'>
                                    <el-button type="text">操作<i class="el-icon-arrow-down"></i></el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        
                                        <template v-if="scope.row.status=='toSubmit'">
                                            <el-dropdown-item @click.native="batchUpdate('toSubmit',scope.row.ibNo,scope.row.id)">提交</el-dropdown-item>
                                            <el-dropdown-item @click.native="batchUpdate('submitAndPutIn',scope.row.ibNo,scope.row.id)">提交并入库</el-dropdown-item>
                                            <el-dropdown-item @click.native="getUpdateInit(scope.row.id)">编辑</el-dropdown-item>
                                        </template>
                                        
                                        <el-dropdown-item v-if="scope.row.status=='toWarehouse'" @click.native="batchUpdate('toWarehouse',scope.row.ibNo,scope.row.id)">确认入库</el-dropdown-item>

                                        <el-dropdown-item v-if="scope.row.status=='toWarehouse'||scope.row.status=='toSubmit'" @click.native="batchUpdate('delete',scope.row.ibNo,scope.row.id)">删除</el-dropdown-item>

                                        <template v-if="scope.row.status=='finished'">
                                            <el-dropdown-item>成本补录</el-dropdown-item>
                                            <el-dropdown-item @click.native="batchUpdate('finished',scope.row.ibNo,scope.row.id)">撤销</el-dropdown-item>
                                        </template>

                                        <el-dropdown-item @click.native="printInBound([scope.row.id])">打印</el-dropdown-item>
                                        <el-dropdown-item>打印产品条码</el-dropdown-item>
                                        <el-dropdown-item>打印FNSKU条码(自定义)</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </div>
                        <div v-else style="">
                            <Picture :url='scope.row.imageUrl' :width='40'></Picture>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="品名/SKU"
                    width="width">
                    <template v-if="scope.row.itemList==undefined" slot-scope="scope">
                        <p :title="scope.row.title" class="oneline">{{scope.row.title}}</p>
                        <p :title="scope.row.sku" class="oneline">{{scope.row.sku}}</p>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="prop"
                    label="FNSKU/店铺"
                    width="width">
                </el-table-column>
                <el-table-column
                    prop="receiveQuantity"
                    label="入库量"
                    width="width">
                </el-table-column>
                <el-table-column
                    prop="goodQuantity"
                    label="良品量"
                    width="width">
                </el-table-column>
                <el-table-column
                    prop="badQuantity"
                    label="次品量"
                    width="width">
                </el-table-column>
                <el-table-column
                    prop="toCheckQuantity"
                    label="待检量"
                    width="width">
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="130">
                </el-table-column>
            </el-table>
        </div>
        <div style="text-align:center;border-top:1px solid #E4E7ED;padding:10px">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
        <!-- 添加入库单 -->
        <el-drawer
            title="添加入库单"
            style="width: 100%;border-top:1px solid #EBEEF5"
            :visible.sync="addOrderBox"
            direction="btt"
            size='89%'
            :before-close="addOrderBoxClose">
            <div slot="title" v-if="detailInfo.ibNo" style="display:flex">
                <h3 style="margin-right:20px">{{detailInfo.ibNo}}</h3>
                <span v-show="detailInfo.statusName=='已撤销'" class="status red">{{detailInfo.statusName}}</span>
                <span v-show="detailInfo.statusName=='待入库'" class="status orange">{{detailInfo.statusName}}</span>
                <span v-show="detailInfo.statusName=='待提交'" class="status green">{{detailInfo.statusName}}</span>
                <span v-show="detailInfo.statusName=='已完成'" class="status">{{detailInfo.statusName}}</span>
            </div>
            <div v-loading='dialogLoading' style="padding:0 20px 65px">
                <el-form ref="addform" :model="addform" label-width="100px" :inline='true'>
                    <el-form-item 
                    label="入库仓库:" 
                    prop="storageId"
                    :rules="[{required:true,message:'请选择入库仓库',trigger:'change'}]">
                        <el-select :disabled='detailInfo.ibNo' placeholder="入库仓库" v-model="addform.storageId">
                            <el-option
                            v-for="item in storageList"
                            :key="item.id"
                            :label="item.value"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item 
                    label="入库类型:" 
                    prop="type"
                    :rules="[{required:true,message:'请选择入库仓库',trigger:'change'}]">
                        <el-select @change="addform.purchaseBy=addform.type=='purchaseIn'?addform.purchaseBy:''" :disabled='detailInfo.ibNo' placeholder="入库类型" v-model="addform.type">
                            <el-option label="其他入库" value="otherIn"></el-option>
                            <el-option label="采购入库" value="purchaseIn"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="供应商:" prop="supplierId">
                        <el-select :disabled='detailInfo.ibNo' placeholder="供应商" v-model="addform.supplierId">
                            <el-option
                            v-for="item in supplierList"
                            :key="item.id"
                            :label="item.value"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="运费:" prop="freight">
                        <el-input placeholder="运费" v-model="addform.freight">
                            <span slot="prefix" style="line-height:34px;color:black">¥</span>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="其他费用:" prop="otherFee">
                        <el-input placeholder="其他运费" v-model="addform.otherFee">
                            <span slot="prefix" style="line-height:34px;color:black">¥</span>
                        </el-input>
                    </el-form-item>
                    <el-form-item 
                    label="费用分配:" 
                    prop="freightShareType"
                    :rules="[{required:true,message:'请选择入库仓库',trigger:'change'}]">
                        <el-select placeholder="费用分配方式" v-model="addform.freightShareType">
                            <el-option
                            v-for="item in freightShareTypeList"
                            :key="item.id"
                            :label="item.value"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item 
                    label="采购员:" 
                    prop="purchaseBy"
                    v-show="addform.type=='purchaseIn'"
                    >
                        <el-select placeholder="采购员" v-model="addform.purchaseBy">
                            <el-option
                            v-for="item in userList"
                            :key="item.id"
                            :label="item.userName"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item><br>
                    <el-form-item label="备注:" prop="tips">
                        <el-input v-model="addform.tips" type="textarea" placeholder="备注" rows="4" cols='100'></el-input>
                    </el-form-item>
                </el-form><br><br>
                <el-button @click="pushProduct">添加产品</el-button>
                <el-button @click.native="batchUpload('商品')">导入产品</el-button><br><br>
                <el-table
                :data='addGoodsList'
                style="width: 100%;border-top:1px solid #EBEEF5"
                :cell-style="{ fontSize: '13px', padding: '7px 0' }"
                :header-cell-style="{
                    fontSize: '13px',
                    padding: '9px 0',
                    marginTop: '10px',
                    background:'#EBEEF5'
                }">
                    <el-table-column
                    prop=""
                    label="图片"
                    mim-width=''>
                    <template slot-scope="scope">
                        <Picture :url='scope.row.imageUrl' :width='40'></Picture>
                    </template>
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="品名/SKU"
                        width="width">
                        <template slot-scope="scope">
                            <template>
                                <p :title="scope.row.title||scope.row.productName" class="oneline">{{scope.row.title||scope.row.productName}}</p>
                                <p :title="scope.row.sku||scope.row.commoditySku" class="oneline">{{scope.row.sku||scope.row.commoditySku}}</p>
                            </template>
                            <!-- <template v-else>
                                <p :title="scope.row.productName" class="oneline">{{scope.row.productName}}</p>
                                <p :title="scope.row.commoditySku" class="oneline">{{scope.row.commoditySku}}</p>
                            </template> -->
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop=""
                    label="店铺"
                    mim-width=''>
                    <el-select placeholder="">
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    </el-table-column>
                    <el-table-column
                    prop=""
                    label="FNSKU"
                    mim-width=''>
                    <template slot-scope="scope">
                        <el-select placeholder="">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop=""
                    label="单价(¥)"
                    mim-width=''>
                    <template slot-scope="scope">
                        <el-input onkeyup="value=value.replace(/^\.+|[^\d.]/g,'')" v-model="scope.row.unitPrice" placeholder="单价"></el-input>
                    </template>
                    </el-table-column>
                    <!-- <el-table-column
                    prop=""
                    label="单位费用(¥)"
                    mim-width=''>
                    </el-table-column> -->
                    <!-- <el-table-column
                    prop=""
                    label="单位入库成本(¥)"
                    mim-width=''>
                    </el-table-column> -->
                    <el-table-column
                    prop=""
                    label="良品量"
                    mim-width=''>
                    <template slot-scope="scope">
                        <el-input onkeyup="value=value.replace(/^\.+|[^\d.]/g,'')" v-model="scope.row.goodQuantity" placeholder="良品量"></el-input>
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop=""
                    label="次品量"
                    mim-width=''>
                    <template slot-scope="scope">
                        <el-input onkeyup="value=value.replace(/^\.+|[^\d.]/g,'')" v-model="scope.row.badQuantity" placeholder="次品量"></el-input>
                    </template>
                    </el-table-column>
                    <!-- <el-table-column
                    prop=""
                    label="货值"
                    mim-width=''>
                    </el-table-column> -->
                    <!-- <el-table-column
                    prop=""
                    label="费用"
                    mim-width=''>
                    </el-table-column> -->
                    <!-- <el-table-column
                    prop=""
                    label="入库成本(¥)"
                    mim-width=''>
                    </el-table-column> -->
                    <el-table-column
                    prop=""
                    label="操作"
                    mim-width=''>
                    <template slot-scope="scope">
                        <el-button @click="addGoodsList.splice(scope.$index,1)" type="text">移除</el-button>
                    </template>
                    </el-table-column>
                </el-table>
                <div style="position:absolute;bottom:0;padding:10px 20px 30px 20px;border-top:1px solid #DCDFE6;width:calc(100% - 60px);text-align:center;background:white;z-index:10">
                    <el-button @click="addOrderBoxClose">取 消</el-button>
                    <el-button @click="sure('toSubmit')">暂存</el-button>
                    <el-button type="primary" @click="sure('toWarehouse')">提交</el-button>
                    <el-button type="primary" @click="sure('finished')">提交并入库</el-button>
                </div>
            </div>
        </el-drawer>
        <!-- 添加商品 -->
        <el-dialog
            title="添加商品"
            :visible.sync="selectGoodsBox"
            width="900px"
            append-to-body
            :before-close="dialogBeforeClose">
            <div v-loading='dialogLoading'>
                <el-input v-model="goodsListInfo.keyword" placeholder="SKU\品名\FNSKU" style="width:250px;margin-right:10px"></el-input>
                <el-button type="primary" @click="getProductListPage('find')">查询</el-button>
                <el-table
                :data='goodsList'
                height='400'
                ref="goodsTable"
                @select="selectGoods"
                @select-all="selectAll"
                style="width: 100%;margin-top:10px"
                :cell-style="{ fontSize: '13px', padding: '7px 0'}"
                :header-cell-style="{
                    fontSize: '13px',
                    padding: '9px 0',
                    marginTop: '10px',
                    background: '#EBEEF5',
                }">
                    <el-table-column
                        type="selection"
                        width="55px" >
                    </el-table-column>
                    
                    <el-table-column
                        prop="prop"
                        label="图片" >
                        <template slot-scope="scope">
                            <Picture :url='scope.row.productImagePath' width='40'></Picture>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="prop"
                        label="品名/SKU" >
                        <template slot-scope="scope">
                            <p :title="scope.row.productName" class="oneline">{{scope.row.productName}}</p>
                            <p :title="scope.row.commoditySku" class="oneline">{{scope.row.commoditySku}}</p>
                        </template>
                    </el-table-column>
                    
                    <el-table-column
                        prop="hadNoticeNumber"
                        label="品牌" >
                    </el-table-column>
                    <el-table-column
                        header-align="center"
                        align="center"
                        prop="productTypeName"
                        label="分类" >
                    </el-table-column>
                </el-table>
                <div style="text-align:center;padding-top:10px">
                    <el-pagination
                    @size-change="goodsSizeChange"
                    @current-change="goodsCurrentChange"
                    :current-page="goodsListInfo.current"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="goodsListInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="goodsListInfo.total">
                    </el-pagination>
                </div>
            </div>
            <div slot="footer">
                <el-button @click="selectGoodsBox = false">取 消</el-button>
                <el-button type="primary" @click="addProduct">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 详情 -->
        <el-drawer
            :visible.sync="detailBox"
            direction="btt"
            size='89%'
            :before-close="detailClose">
            <div slot="title" style="display:flex">
                <h3 style="margin-right:20px">{{detailInfo.ibNo}}</h3>
                <span v-show="detailInfo.status=='revoked'" class="status red">{{detailInfo.statusName}}</span>
                <span v-show="detailInfo.status=='toWarehouse'" class="status orange">{{detailInfo.statusName}}</span>
                <span v-show="detailInfo.status=='toSubmit'" class="status green">{{detailInfo.statusName}}</span>
                <span v-show="detailInfo.status=='finished'" class="status">{{detailInfo.statusName}}</span>
                <div style="flex:1;text-align:right;margin-right:50px;height:14px">
                    <el-dropdown style="margin-right:10px" trigger="click" placement="bottom">
                        <el-button>操作<i class="el-icon-arrow-down"></i></el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>打印产品条码</el-dropdown-item>
                            <el-dropdown-item>打印FNSKU条码</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <template v-if="detailInfo.status=='toSubmit'">
                        <el-button @click.native="getUpdateInit(detailInfo.id)">编辑</el-button>
                        <el-button @click.native="batchUpdate('submitAndPutIn',detailInfo.ibNo,detailInfo.id)">提交并入库</el-button>
                        <el-button @click.native="batchUpdate('toSubmit',detailInfo.ibNo,detailInfo.id)" type="primary">提交</el-button>
                    </template>
                    <template  v-if="detailInfo.status=='toWarehouse'">
                        <el-button>打印</el-button>
                        <el-button @click.native="batchUpdate('delete',detailInfo.ibNo,detailInfo.id)">删除</el-button>
                        <el-button @click.native="batchUpdate('toWarehouse',detailInfo.ibNo,detailInfo.id)" type="primary">确认入库</el-button>
                    </template>
                    <template v-if="detailInfo.status=='finished'">
                        <el-button>打印</el-button>
                        <el-button @click.native="batchUpdate('finished',detailInfo.ibNo,detailInfo.id)" type="primary">撤销</el-button>
                    </template>
                    <template v-if="detailInfo.status=='revoked'">
                        <el-button>打印</el-button>
                        <el-button type="primary" @click="detailClose">关闭</el-button>
                    </template>
                </div>
            </div>
            <div style="padding:0 20px">
                <el-steps :space="340" :active="stepNumber" finish-status="success">
                    <el-step title="创建">
                        <p slot="description">
                            {{detailInfo.createName}} - {{detailInfo.createTime}}
                        </p>
                    </el-step>
                    <el-step title="提交">
                        <p slot="description">
                            {{detailInfo.submitByName}} - {{detailInfo.submitTime}}
                        </p>
                    </el-step>
                    <el-step title="入库">
                        <p slot="description">
                            {{detailInfo.putInByName}} - {{detailInfo.putInTime}}
                        </p>
                    </el-step>
                    <el-step title="完成">
                        <p slot="description">
                            {{detailInfo.putInByName}} - {{detailInfo.putInTime}}
                        </p>
                    </el-step>
                    <el-step v-if="stepNumber==5" title="撤销" status='error' style="color:red">
                        <p slot="description">
                            {{detailInfo.revokeByName}} - {{detailInfo.revokeTime}}
                        </p>
                    </el-step>
                </el-steps>
                <el-form style="margin-top:30px" ref="form" :inline='true' :model="form" label-width="120px">
                    <el-form-item label="入库仓库：" style="width:15%">
                        {{detailInfo.storageName}}
                    </el-form-item>
                    <el-form-item label="入库类型：" style="width:15%">
                        {{detailInfo.typeName}}
                    </el-form-item>
                    <el-form-item label="供应商：" style="width:15%">
                        {{detailInfo.supplierName}}
                    </el-form-item>
                    <el-form-item label="运费：" style="width:15%">
                        ¥{{detailInfo.freight}}
                    </el-form-item>
                    <el-form-item label="其他费用：" style="width:15%">
                        ¥{{detailInfo.otherFee}}
                    </el-form-item>
                    <el-form-item label="费用分配方式：" style="width:15%">
                        {{detailInfo.freightShareTypeName}}
                    </el-form-item>
                    <el-form-item label="采购单号：" style="width:15%">
                        {{detailInfo.purchaseNumber}}
                    </el-form-item>
                    <el-form-item label="采购员：" style="width:15%">
                        {{detailInfo.purchaseByName}}
                    </el-form-item>
                    <el-form-item label="备注：" style="width:30%">
                        {{detailInfo.tips}}
                    </el-form-item>
                </el-form><br><br>
                <el-tabs v-model="detailCard" type="card" @tab-click="changeDetailTable">
                  <el-tab-pane label="入库产品" name="first">
                   <el-table
                        :data='detailInfo.itemList'
                        :cell-style="{ fontSize: '13px', padding: '7px 0' }"
                        :header-cell-style="{
                            fontSize: '13px',
                            padding: '9px 0',
                            marginTop: '10px',
                        }">
                            <el-table-column
                                prop="prop"
                                label="图片" >
                                <template slot-scope="scope">
                                    <Picture :url='scope.row.imageUrl' :width='40'></Picture>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="prop"
                                label="品名/SKU" >
                                <template v-if="scope.row.itemList==undefined" slot-scope="scope">
                                    <p :title="scope.row.title" class="oneline">{{scope.row.title}}</p>
                                    <p :title="scope.row.sku" class="oneline">{{scope.row.sku}}</p>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="prop"
                                label="店铺" >
                            </el-table-column>
                            <el-table-column
                                prop="prop"
                                label="FNSKU" >
                            </el-table-column>
                            <el-table-column
                                prop="unitPrice"
                                label="单价(¥)" >
                            </el-table-column>
                            <el-table-column
                                prop="unitCost"
                                label="单位费用(¥)" >
                                <span slot-scope="scope">{{scope.row.unitCost | Digits}}</span>
                            </el-table-column>
                            <el-table-column
                                prop="unitPutCost"
                                label="单位入库成本(¥)" >
                                <span slot-scope="scope">{{scope.row.unitPutCost | Digits}}</span>
                            </el-table-column>
                            <el-table-column
                                prop="receiveQuantity"
                                label="入库量" >
                            </el-table-column>
                            <el-table-column
                                prop="goodQuantity"
                                label="良品量" >
                            </el-table-column>
                            <el-table-column
                                prop="badQuantity"
                                label="次品量" >
                            </el-table-column>
                            <el-table-column
                                prop="toCheckQuantity"
                                label="待检量" >
                            </el-table-column>
                            <el-table-column
                                prop="value"
                                label="货值" >
                                <span slot-scope="scope">{{scope.row.value | Digits}}</span>
                            </el-table-column>
                            <el-table-column
                                prop="cost"
                                label="费用" >
                                <span slot-scope="scope">{{scope.row.cost | Digits}}</span>
                            </el-table-column>
                            <el-table-column
                                prop="putCost"
                                label="入库成本" >
                                <span slot-scope="scope">{{scope.row.putCost | Digits}}</span>
                            </el-table-column>
                   </el-table>
                  </el-tab-pane>
                  <el-tab-pane label="关联单据" name="second">
                    <el-tabs v-model="associative" @tab-click="handleClick">
                            <el-tab-pane label="关联收货单" name="first">
                                <span slot="label" v-if="detailInfo.associativeRecive!=[]">
                                    关联收货单({{detailInfo.associativeRecive.length}})
                                </span>
                                <el-table
                                    :data="detailInfo.associativeRecive"
                                    style="width: 100%;margin-top:10px"
                                    :cell-style="{ fontSize: '13px', padding: '7px 0'}"
                                    :header-cell-style="{
                                        fontSize: '13px',
                                        padding: '9px 0',
                                        marginTop: '10px',
                                        background: '#EBEEF5',
                                    }">
                                    <el-table-column
                                        prop="crNo"
                                        label="收货单号">
                                    </el-table-column>
                                    <el-table-column
                                        prop="prop"
                                        label="图片" >
                                        <template slot-scope="scope">
                                            <Picture :url='scope.row.imageUrl' width='40'></Picture>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="prop"
                                        label="品名/SKU" >
                                        <template slot-scope="scope">
                                            <p :title="scope.row.title" class="oneline">{{scope.row.title}}</p>
                                            <p :title="scope.row.sku" class="oneline">{{scope.row.sku}}</p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="prop"
                                        label="店铺/FNSKU">
                                    </el-table-column>
                                    <el-table-column
                                        prop="expectArrivalTime"
                                        label="预计到货时间">
                                    </el-table-column>
                                    <el-table-column
                                        prop="noticeQuantity"
                                        label="通知收货量">
                                    </el-table-column>
                                    <el-table-column
                                        prop="receiveQuantity"
                                        label="实际收货量">
                                    </el-table-column>
                                    <el-table-column
                                        prop="toCheckQuantity"
                                        label="待检量">
                                    </el-table-column>
                                    <el-table-column
                                        prop="hadCheckQuantity"
                                        label="已检量">
                                    </el-table-column>
                                    <el-table-column
                                        prop="prop"
                                        label="创建人/创建时间">
                                        <span slot-scope='scope'>
                                            <p>{{scope.row.createByName}}</p>
                                            {{scope.row.createTime}}
                                        </span>
                                    </el-table-column>
                                    <el-table-column
                                        prop="receiveStatusName"
                                        label="单据状态">
                                    </el-table-column>
                                    <el-table-column
                                        prop="checkStatusName"
                                        label="质检状态">
                                    </el-table-column>
                                </el-table>
                            </el-tab-pane>
                            <el-tab-pane label="关联质检单" name="second">
                                <span slot="label">
                                    关联收货单({{detailInfo.associativeChecking.length}})
                                </span>
                                <el-table
                                    :data="detailInfo.associativeChecking"
                                    style="width: 100%;margin-top:10px"
                                    :cell-style="{ fontSize: '13px', padding: '7px 0'}"
                                    :header-cell-style="{
                                        fontSize: '13px',
                                        padding: '9px 0',
                                        marginTop: '10px',
                                        background: '#EBEEF5',
                                    }">
                                    <el-table-column
                                        prop="qcNo"
                                        label="收货单号">
                                    </el-table-column>
                                    <el-table-column
                                        prop="prop"
                                        label="图片" >
                                        <template slot-scope="scope">
                                            <Picture :url='scope.row.imageUrl' width='40'></Picture>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="prop"
                                        label="品名/SKU" >
                                        <template slot-scope="scope">
                                            <p :title="scope.row.title" class="oneline">{{scope.row.title}}</p>
                                            <p :title="scope.row.sku" class="oneline">{{scope.row.sku}}</p>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="prop"
                                        label="店铺/FNSKU">
                                    </el-table-column>
                                    <el-table-column
                                        prop="storageName"
                                        label="仓库">
                                    </el-table-column>
                                    <el-table-column
                                        prop="checkByName"
                                        label="质检员">
                                    </el-table-column>
                                    <el-table-column
                                        prop="checkTime"
                                        label="质检时间">
                                    </el-table-column>
                                    <el-table-column
                                        prop="checkingQuantity"
                                        label="质检量">
                                    </el-table-column>
                                    <el-table-column
                                        prop="goodQuantity"
                                        label="良品量">
                                    </el-table-column>
                                    <el-table-column
                                        prop="badQuantity"
                                        label="次品量">
                                    </el-table-column>
                                    <el-table-column
                                        prop="statusName"
                                        label="质检状态">
                                    </el-table-column>
                                </el-table>
                            </el-tab-pane>
                    </el-tabs>
                  </el-tab-pane>
                  <el-tab-pane label="操作记录" name="third">
                      <el-table
                        height="400px"
                        :data="operationLogList"
                        style="width: 100%;margin-top:10px"
                        :cell-style="{ fontSize: '13px', padding: '7px 0'}"
                        :header-cell-style="{
                            fontSize: '13px',
                            padding: '9px 0',
                            marginTop: '10px',
                        }">
                        <el-table-column
                            prop="createTime"
                            label="操作时间" 
                            width="150">
                        </el-table-column>
                        <el-table-column
                            prop="createByName"
                            label="操作人" 
                            width="100">
                        </el-table-column>
                        <el-table-column
                            prop="operateTypeName"
                            label="操作类型" 
                            width="100">
                        </el-table-column>
                        <el-table-column
                            prop="details"
                            label="操作详情" >
                        </el-table-column>
                      </el-table>
                  </el-tab-pane>
                </el-tabs>
            </div>
        </el-drawer>
        <el-dialog
            :title="'批量导入'+uploadType"
            :visible.sync="uploadBox"
            width="700px"
            :destroy-on-close='true'
            :before-close="uploadBoxClose">
            <div>
                <el-upload
                class="upload-demo"
                :action="action"
                :headers="mytoken"
                :on-success="uploadSuccess"
                multiple
                :on-exceed="handleExceed"
                :file-list="fileList">
                <el-button size="small" type="primary">点击上传</el-button>
                <el-button @click.native="downTemplate" slot="tip" type="text" style="margin-left:15px" class="el-upload__tip">下载模板</el-button>
                </el-upload>
                <ul style="line-height:25px;margin-top:20px">
                    <li>支持导入格式为xlsx的文件(手动修改文件后缀无效)</li>
                    <li>模版中的表头不可更改，不可删除</li>
                    <li>数据记录不要超过500条</li>
                    <li>当某个入库单中有一条数据错误时，整张入库单的数据需重新导入；可下载失败原因修改后再次导入</li>
                    <li>标识号相同的数据合并到同一张入库单，同一张入库单的绿色列的数据需要保持一致</li>
                </ul><br><br>
                <el-table
                    :data="faildList"
                    v-if="faildList.length>0"
                    style="width: 100%"
                    :cell-style="{ fontSize: '13px', padding: '7px 0',textAlign:'left' }"
                    :header-cell-style="{
                        fontSize: '13px',
                        padding: '9px 0',
                        marginTop: '10px',
                        background: '#EBEEF5',
                        textAlign:'center'
                    }">
                    <el-table-column
                        prop="value"
                        label="失败原因"
                        width="width">
                    </el-table-column>
                </el-table>
            </div>
            <div slot="footer">
                <el-button @click="uploadBoxClose">取 消</el-button>
                <el-button type="primary" @click="uploadBoxClose">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {pageInit,
        listPage,
        addInit,
        getProductListPage,
        warehousePutInNote,
        batchUpdate,
        updateInit,
        getRelatedQualityCheckNoteNo,
        getRelatedReceiveNoteDo,
        update,
        operationLog,
        updateTips,
        printInBound,
        detail} from '@/api/InventoryManage/inboundManual.js'
import {Digits} from '@/utils/Digits.js'
export default {
    data(){
        var token = window.sessionStorage.getItem('token')
        return{
            mytoken:{token:token},
            tableData:[],
            loading:false,
            addOrderBox:false,
            dialogLoading:false,
            detailBox:false,
            detailCard:'first',
            associative:'first',
            putInType:[],
            tipsContent:'',
            searchTimeTypeList:[],
            searchTypeList:[],
            storageList:[],
            supplierList:[],
            userList:[],
            pickerOptions:{
              disabledDate(time){
                  return  time.getTime() > new Date().getTime() - 8.64e6 ;
              }
            },
            pageInfo:{
                current:1,
                timeType:'create_time',
                type:'',
                storageId:'',
                searchType:'ib_no',
                keyword:'',
                pageSize:10,
                status:'all'
            },
            total:0,
            // 新增
            freightShareTypeList:[],
            putInTypeList:[],
            storageList:[],
            supplierList:[],

            addGoodsList:[],
            addform:{
                freight:'',
                freightShareType:'0',
                isQuick:'',
                otherFee:'',
                purchaseNo:'',
                receiveId:'',
                status:'',
                storageId:'',
                supplierId:'',
                purchaseBy:'',
                tips:'',
                type:'',
            },
            goodsListInfo:{
                pageSize:10,
                current:1,
                keyword:'',
                total:0
            },
            goodsList:[],
            selectedList:[],
            selectGoodsBox:false,
            selectTable:[],

            // 详情
            detailInfo:{
                associativeRecive:[],
                associativeChecking:[]
            },
            operationLogList:[], //操作日志
            uploadType:'',//批量到入类型
            uploadBox:false,
            fileList:[],
            faildList:[]

        }
    },
    mounted(){
        this.getListPage()
    },
    filters:{
        Digits(value){
            return Digits(value,2)
        }
    },
    computed:{
        stepNumber(){
            switch(this.detailInfo.status){
                case 'toSubmit':
                return 1
                case 'toWarehouse':
                return 2;
                case 'finished':
                return 4
                case 'revoked':
                return 5
            }
        },
        action(){
            if(this.uploadType=='入库单'){
                return process.env.VUE_APP_API_URL+'/api/warehousePutInNote/importPutInFile'
            }else if(this.uploadType=='商品'){
                return process.env.VUE_APP_API_URL+'/api/warehousePutInNote/importProductWhenAddOrUpdate'
            }
        }
    },
    methods:{
        downTemplate(){
            if(this.uploadType=='入库单'){
                window.location.href='入库单导入模板.xlsx'
            }else if(this.uploadType=='商品'){
                window.location.href='导入入库商品.xlsx'
            }
        },
        batchUpload(type){
            if(type=='商品'){
                this.$refs.addform.validate(valid=>{
                    if(!valid) return
                    this.uploadType=type
                    this.uploadBox=true
                })
            }else {
                this.uploadType=type
                this.uploadBox=true
            }
        },
        uploadBoxClose(){
            this.uploadBox=false
            this.fileList=[]
            this.faildList=[]
        },
        uploadSuccess(res,file,fileList){
            if(this.uploadType=='商品'){
                if(res.code==200){
                    if(this.addGoodsList.length==0){
                        this.addGoodsList=res.data.map(item=>{
                            item.unitPrice=item.purchasePrice
                            item.productId = item.id
                            return item;
                        })

                        this.uploadBoxClose()
                        this.$message.success(res.message)
                        return
                    }
                    res.data.forEach(item=>{
                        item.unitPrice=item.purchasePrice
                        item.productId=item.id
                        
                        // 相同项替换
                        this.addGoodsList.forEach((i,ind)=>{
                            if(item.productId==i.productId){
                                this.addGoodsList.splice(ind,1,item)
                            }
                        })
                        const arr = this.addGoodsList.map(item=>{
                            return item.productId
                        })
                        if(!arr.includes(item.productId)){
                            this.addGoodsList.push(item)
                        }
                    })
                    
                    
                    this.uploadBoxClose()
                    this.$message.success(res.message)
                }else{
                    this.$message.error(res.message)
                    this.faildList=res.data.map((item,index)=>{
                        return {
                            id:index,
                            value:item
                        }
                    })
                }
            }else{
                console.log(res);
                if(res.code==200){
                    this.$message.success(res.message)
                    this.uploadBox=false
                    this.getListPage()
                }else{
                    this.$message.error(res.message)
                    this.faildList=res.data.map((item,index)=>{
                        return {
                            id:index,
                            value:item
                        };
                    })
                }
            }
        },
        changeDetailTable(){
            if(this.detailCard=='second'){
                this.getRelatedQualityCheckNoteNo(this.detailInfo.id)
                this.getRelatedReceiveNoteDo(this.detailInfo.id)
            }else if(this.detailCard=='third'){
                this.operationLog(this.detailInfo.id,'putIn')
            }
        },
        operationLog(id,type){
            operationLog({objectId:id,operateObject:type}).then(res=>{
                this.operationLogList=res.data.data
            })
        },
        // 表格合并单元格
        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            if (row.itemList!=undefined) {
                if (columnIndex == 1) {
                    return [1, 8];
                } else {
                    return [1, 1];
                }
            }else{
                [0,1]
            }
        },
        printInBound(id){
            console.log(id);
            this.loading=true
            printInBound({ids:id}).then(res=>{
                this.loading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message,'等待跳转')
                    window.open(res.data.data)
                }else{
                    this.$message.error(res.data.message)
                }
            })
        },
        detailClose(){
            this.detailBox=false
            this.detailInfo={
                associativeRecive:[],
                associativeChecking:[]
            }
            this.detailCard='first'
            this.associative='first'
            this.addOrderBoxClose()
        },
        getUpdateInit(id){
            detail(id).then(res=>{
                if(res.data.code==200){
                    this.addInit()
                    for(var key in this.addform){
                        this.addform[key] =res.data.data[key]
                    }
                    this.addform.supplierId=res.data.data.supplierId
                    this.addform.freightShareType=String(res.data.data.freightShareType)
                    this.$set(this.detailInfo,'ibNo',res.data.data.ibNo)
                    this.$set(this.detailInfo,'statusName',res.data.data.statusName)
                    this.$set(this.detailInfo,'id',res.data.data.id)
                    this.addGoodsList = res.data.data.itemList.map(item=>{
                        item.id=item.productId
                        return item
                    })
                    
                }
            })
        },
        getRelatedQualityCheckNoteNo(id){
            getRelatedQualityCheckNoteNo(id).then(res=>{
                this.$set(this.detailInfo,'associativeChecking',res.data.data)
            })
        },
        getRelatedReceiveNoteDo(id){
            getRelatedReceiveNoteDo(id).then(res=>{
                this.$set(this.detailInfo,'associativeRecive',res.data.data)
            })
        },
        getDetail(id){
            this.loading=true
            detail(id).then(res=>{
                this.loading=false
                if(res.data.code==200){
                    // this.getRelatedQualityCheckNoteNo(id)
                    // this.getRelatedReceiveNoteDo(id)
                    // this.operationLog(id,'putIn')
                    for(var key in res.data.data){
                        this.$set(this.detailInfo,key,res.data.data[key])
                    }
                    this.detailBox=true
                }else {
                    this.$message.error(res.data.message)
                }
            })
        },
        select(val){
            this.selectTable = val
        },
        hiddenTip(id){
            this.$confirm('确认修改备注, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading=true
                updateTips({id:id,tips:this.tipsContent}).then(res=>{
                    this.loading=false
                    if(res.data.code==200){
                        this.$message.success(res.data.message)
                        this.getListPage()
                    }else {
                        this.$message.error(res.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },
        batchPrintInBound(){
            if(this.selectTable.length==0) return this.$message.error('请选择具体内容')
            this.printInBound(this.selectTable.map(item=>item.id))
        },
        batchUpdate(status,info,id){
            let str = ''
            let ids = []
            if(id){
                ids=[id]
                if(status=='delete'){
                    str=`确定删除 ${info} ?
                    删除后不可恢复`
                }else if(status=='toSubmit'){
                    str=`确定提交 ${info} ?
                    提交后不可再次编辑`
                }else if(status=='submitAndPutIn'){
                    str=`确定提交并入库 ${info} ?
                    入库后，入库单状态变更为【已完成】`
                }else if(status=='toWarehouse'){
                    str=`确定入库 ${info} ?
                    入库后，入库单状态变更为【已完成】`
                }else if(status=='finished'){
                    str=`确定撤销 ${info} ?
                    撤销后不可恢复`
                }
            }else{
                ids=this.selectTable.map(item=>item.id)
                if(ids.length==0) return this.$message.error('请选择具体内容')
                const arr = this.selectTable.filter(item=>{
                    return item.status==status
                })
                if(status=='toSubmit'||status=='toWarehouse'){
                    if(arr.length!=this.selectTable.length){
                        this.$message.error(info)
                        return
                    }else{
                        if(status=='toSubmit'){
                            str=`共${arr.length}个入库单，确定提交？提交后不可再次编辑`
                        }else if(status=='toWarehouse'){
                            str=`共 ${arr.length} 个入库单,确定入库？确认入库后，入库单状态变更为【已完成】`
                        }
                    }
                }else if(status=='delete'){
                    const arr = this.selectTable.filter(item=>{
                        return item.status=='toSubmit'||item.status=='toWarehouse'
                    })
                    if(arr.length!=this.selectTable.length){
                        this.$message.error(info)
                        return
                    }else{
                        str=`共${arr.length}个入库单，确定删除？删除后不可恢复`
                    }
                }else if(status=='submitAndPutIn'){
                    const arr = this.selectTable.filter(item=>{
                        return item.status=='toSubmit'
                    })
                    if(arr.length!=this.selectTable.length){
                        this.$message.error(info)
                        return
                    }else{
                        str=`共${arr.length}个入库单，确定提交并入库？入库后，状态变更为【已完成】`
                    }
                }
            }
            
            this.$confirm(`${str}, 是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loading=true
                batchUpdate({type:status,ids:ids}).then(res=>{
                    console.log(res);
                    this.loading=false
                    if(res.data.code==200){
                        this.$message.success(res.data.message)
                        this.getListPage()
                        if(this.detailBox) this.detailClose()
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消操作'
                });          
            });
        },
        sure(status){
            this.$refs.addform.validate(valid=>{
                if(!valid) return
                if(this.addGoodsList.length==0) return this.$message.error('请选择商品')
                const flag = this.addGoodsList.some(item=>{
                    return Number(item.goodQuantity)<=0&&Number(item.badQuantity)<0
                })
                if(flag) return this.$message.error('良品量和次品量不能都为0')
                const arr = this.addGoodsList.map(item=>{
                    return {
                        badQuantity:item.badQuantity,
                        goodQuantity:item.goodQuantity,
                        productId:item.productId,
                        receiveId:item.receiveId,
                        receiveQuantity:item.receiveQuantity,
                        unitPrice:item.unitPrice,
                    };
                })
                this.dialogLoading=true
                if(!this.detailInfo.ibNo){    //新增
                    warehousePutInNote({...this.addform,productList:arr,status:status,isQuick:status=='finished'?1:0}).then(res=>{
                        this.dialogLoading=false
                        if(res.data.code==200){
                            this.$message.success(res.data.message)
                            this.getListPage()
                            this.addOrderBoxClose()
                        }else {
                            this.$message.error(res.data.message)
                        }
                    })
                }else {
                    update({...this.addform,productList:arr,status:status,id:this.detailInfo.id,isQuick:status=='finished'?1:0}).then(res=>{
                        this.dialogLoading=false
                        if(res.data.code==200){
                            this.$message.success(res.data.message)
                            this.getListPage()
                            this.addOrderBoxClose()
                        }else {
                            this.$message.error(res.data.message)
                        }
                    })
                }
            })
        },
        addOrderBoxClose(){
            this.$refs.addform.resetFields()
            this.addOrderBox=false
            this.addGoodsList=[]
            this.selectedList=[]
            this.detailInfo={
                associativeRecive:[],
                associativeChecking:[]
            }
            this.addform={
                freight:'',
                freightShareType:'0',
                isQuick:'',
                otherFee:'',
                purchaseNo:'',
                receiveId:'',
                status:'',
                storageId:'',
                supplierId:'',
                tips:'',
                type:'',
                purchaseBy:'',
            }
            this.detailClose()
        },
        addProduct(){
            // this.addGoodsList=this.selectedList
            const arr = this.addGoodsList.map(item=>item.id)

            this.selectedList.forEach(item=>{
                if(!arr.includes(item.id)){
                    this.addGoodsList.push(item)
                }
            })

            this.selectGoodsBox=false
            this.goodsListInfo.keyword=''
        },
        pushProduct(){
            this.selectedList=this.addGoodsList.map(item=>item)
            this.getProductListPage()
        },
        getProductListPage(info){
            if(info=='find') this.goodsListInfo.current=1
            this.$refs.addform.validate(valid=>{
                if(!valid) return
                this.dialogLoading=true
                getProductListPage({
                    current:this.goodsListInfo.current,
                    pageSize:this.goodsListInfo.pageSize,
                    keyword:this.goodsListInfo.keyword,
                }).then(res=>{
                    console.log(res);
                    this.selectGoodsBox=true
                    this.dialogLoading=false
                    this.goodsList = res.data.values
                    this.goodsListInfo.total=res.data.pageInfo.total
                    const arr = this.selectedList.map(item=>{
                        return item.id
                    })
                    this.goodsList.forEach((item,index)=>{
                        if(arr.includes(item.id)){
                            this.$nextTick(()=>{
                                this.$refs.goodsTable.toggleRowSelection(this.goodsList[index]);
                            })
                        }
                    })
                })
            })
        },
        goodsSizeChange(val){
            this.goodsListInfo.pageSize=val
            this.getProductListPage()
        },
        goodsCurrentChange(val){
            this.goodsListInfo.current=val
            this.getProductListPage()
        },
        selectGoods(val,row){
            let arr = this.selectedList.map(item=>item.id)
            if(!arr.includes(row.id)){
                this.selectedList.push({
                    ...row,
                    tips:'',
                    badQuantity:0,
                    goodQuantity:0,
                    productId:row.id,
                    receiveId:'',
                    receiveQuantity:'',
                    unitPrice:row.purchasePrice
                })
            }else{
                this.selectedList.splice(arr.indexOf(row.id),1)
            }
            console.log(this.addGoodsList);

            // this.selectedList=val.map(item=>{
            //     return {
            //         ...item,
            //         tips:'',
            //         badQuantity:0,
            //         goodQuantity:0,
            //         productId:item.id,
            //         receiveId:'',
            //         receiveQuantity:'',
            //         unitPrice:item.purchasePrice
            //     };
            // })
        },
        selectAll(val){
            console.log(val);
            const arr = this.selectedList.map(item=>item.id)
            if(val.length!=0){
                val.forEach(item=>{
                    if(!arr.includes(item.id)){
                        this.selectedList.push({
                            ...item,
                            tips:'',
                            badQuantity:0,
                            goodQuantity:0,
                            productId:item.id,
                            receiveId:'',
                            receiveQuantity:'',
                            unitPrice:item.purchasePrice
                        })
                    }
                })
            }else{
                const arr = this.goodsList.map(item=>item.id)
                arr.forEach(item=>{
                    this.selectedList.forEach((i,index)=>{
                        if(i.id==item.id) this.selectedList.splice(index,1)
                    })
                })
            }
        },
        addInit(){
            this.loading=true
            addInit().then(res=>{
                this.loading=false
                console.log(res);
                if(res.data.code==200){
                    this.addOrderBox=true
                    this.freightShareTypeList=res.data.data.freightShareTypeList
                    this.putInTypeList=res.data.data.putInTypeList
                    this.storageList=res.data.data.storageList
                    this.supplierList=res.data.data.supplierList
                    this.userList=res.data.data.userList
                }else {
                    this.$message.error(res.data.message)
                }
            })
        },
        handleCurrentChange(val){
            this.pageInfo.current=val
            this.getListPage()
        },
        handleSizeChange(val){
            this.pageInfo.pageSize=val
            this.getListPage()
        },
        getPageInit(){
            this.loading=true
            pageInit().then(res=>{
                this.loading=false
                this.putInType=res.data.data.putInType
                this.searchTimeTypeList=res.data.data.searchTimeTypeList
                this.searchTypeList=res.data.data.searchTypeList
                this.storageList=res.data.data.storageList
                this.supplierList=res.data.data.supplierList
                this.statusTypeList=res.data.data.statusTypeList
            })
        },
        getListPage(){
            this.loading=true
            listPage({...this.pageInfo,status:this.pageInfo.status=='all'?'':this.pageInfo.status}).then(res=>{
                this.loading=false
                this.tableData=res.data.values
                this.total=res.data.pageInfo.total
                this.getPageInit()
            })
        },
        find(){
            console.log(1);
            this.pageInfo.current=1
            this.getListPage()
        }
    }
}
</script>

<style scoped lang='scss'>
.inboundManual{
  width: 100%;
  height: calc(100% - 68px);
  display: flex;
  flex-direction: column;
}
::v-deep .el-input-group__prepend {
    background-color: #fff;
  }
// 表格样式
::v-deep .outTable .cell .el-table__expand-icon{
    display: none;
}
::v-deep .outTable .el-table__row--level-1{
    .cell{
        >.el-checkbox{
            display: none;
        }
    }
}
::v-deep .outTable .el-table__row--level-0{
    background:#EBEEF5;
}
.tableMenu{
    display: flex;
    align-items: center;
    >div{
        margin-right:30px;
        span:nth-of-type(1){
            color:#999999;
            font-size: 12px;
        }
    }
}
.status{
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    padding: 0;
}
::v-deep .outTable .cell .el-table__indent{
    display: none;
}
::v-deep .outTable .cell .el-table__placeholder{
    display: none;
}
.option {
    >div{
        >span:nth-of-type(1){
            color: #999999;
        }
    }
}
.oneline{
    white-space: nowrap;  
    text-overflow:ellipsis;
    overflow:hidden;
}
.orange{
    color: orange !important;
    border-color: orange;
}
.red{
    color: red !important;
    border-color: red ;
}
.green{
    color: #91D876 !important;
    border-color: #91D876;
}

// ::v-deep .el-steps{
//     .is-horizontal:nth-of-type(5){
//         color: red;
//         .el-step__head{
//             color: red;
//             border-color: red;
//         }
//         .el-step__main{
//             >div:nth-of-type(1){
//                 color: red;
//             }
//         }
//     }
// }
</style>